import { state } from "../analysis/state"

import { getters } from "../analysis/getters"

import { mutations } from "../analysis/mutations"

//诊所列表
const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
