export const state = {
  isAuthenticated: false,
  spinning: false,
  loading: true,
  disabled: true,
  query: {
    id: undefined,
    did: undefined,
    tab: undefined,
    mid: undefined,
    modelName: undefined,
    tid: undefined,
    vid: undefined,
    fullPath: undefined,
  },
}
