import Vue from "vue"
import App from "./App.vue"
import Antd from "ant-design-vue"
import VueRouter from "vue-router"
import router from "./router/index"
// import "@/assets/styles/reset.css"
import "ant-design-vue/dist/antd.less"
import store from "./store/index"

Vue.use(Antd)
Vue.use(VueRouter)
Vue.config.productionTip = true

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app")
