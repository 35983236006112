import axios from "axios"
import config from "./config"
import qs from "qs"
import { router } from "@/router/index"
import { message, notification } from "ant-design-vue"

axios.defaults.timeout = 10 * 60 * 1000 // 10 分钟
// axios.defaults.timeout = 1000
class HttpRequest {
  constructor(params) {
    this.baseUrl = params.baseUrl
    this.queue = {}
  }
  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
    }
    return config
  }

  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(
      (config) => {
        message.destroy()
        if (config.method === "get") {
          //如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
          config.paramsSerializer = function(params) {
            return qs.stringify(params, {
              arrayFormat: "repeat",
            })
          }
        }
        return config
      },
      (error) => {
        return error
      }
    )

    // 响应拦截
    instance.interceptors.response.use(
      (res) => {
        message.destroy()
        const { data, status } = res
        if (
          res.data &&
          !(res.status === 200 || res.status === 201 || res.status === 204)
        ) {
          const error = {
            code: res.data.code,
            message: res.data.message,
          }
          return Promise.reject(error)
        } else {
          return {
            data,
            status,
          }
        }
      },
      (error) => {
        if (error.code === "ECONNABORTED") {
          return Promise.reject({
            code: 500,
            message: "Request time out",
          })
        }
        if (error && error.response) {
          console.log(error.response.status, "error.response.status")
          switch (error.response.status) {
            case 401:
              localStorage.removeItem("isAuthenticated")
              router.push("/login")
              return Promise.reject({
                code: 401,
                message: "Authenticated failed",
              })
            default:
              return Promise.reject(error.response.data)
          }
        }
        return Promise.reject(error)
      }
    )
  }

  request(options) {
    axios.defaults.withCredentials = true
    const instance = axios.create()
    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance, options.url)
    return instance(options)
  }
}

const http = new HttpRequest({
  baseUrl:
    process.env.NODE_ENV === "development"
      ? config.baseUrl.dev
      : config.baseUrl.prod,
})

export default http
