import VueRouter from "vue-router"

// 500 错误页面
export const page500 = {
  path: "/500",
  meta: {
    title: "500-服务端错误",
  },
  name: "error_500",
  component: () => import("../views/error/500"),
}

// 作为Main组件的子页面展示写在appRouter里
export const appRouter = [
  {
    path: "/",
    // 先默认 首页为 dashboard，登录功能增加后
    redirect: "/login",
  },
  {
    name: "SIKB",
    path: "/SIKB",
    meta: {
      hide: false,
    },
    component: () => import("../views/KnowlageLibary/index.vue"),
    children: [
      // {
      //   name: 'SIKB-heat-analysis',
      //   path: '/SIKB/heat-analysis',
      //   meta: {
      //     hide: false
      //   },
      //   component: () => import('../views/KnowlageLibary/heatAnalysis/index')
      // },
      {
        name: "SIKB-connectivity-search",
        path: "/SIKB/connectivity-search",
        meta: {
          hide: false,
        },
        component: () =>
          import("../views/KnowlageLibary/directionalAnalysis/index.vue"),
      },
      {
        name: "SIKB-heat-analysis",
        path: "/SIKB",
        meta: {
          hide: false,
        },
        component: () =>
          import("../views/KnowlageLibary/heatAnalysis/index.vue"),
      },
    ],
  },
  {
    name: "Home",
    path: "/Home",
    meta: {
      hide: false,
    },
    component: () => import("../views/Home/index.vue"),
  },
  {
    name: "Login",
    path: "/login",
    meta: {
      hide: false,
    },
    component: () => import("../views/login/index.vue"),
  },
]

// 所有上面定义的路由都要写在下面的routers里
export const routers = [page500, ...appRouter]

// 路由配置
const RouterConfig = {
  mode: "hash",
  routes: routers,
}

export const router = new VueRouter(RouterConfig)

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error)
}

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated")
  if (to.name !== "Login" && !isAuthenticated) {
    next({ name: "Login" })
  } else {
    next()
  }
})
export default router
