<template>
  <div>
    <lottie
      :options="defaultOptions"
      :height="100"
      :width="100"
      class="box"
      v-if="!!spinning"
    />
    <div class="wrapper">
      <header id="header" v-if="getHideHeader()">
        <div class="logo">
          <img src="@/assets/images/logo.png" />
        </div>
        <div class="tabs">
          <!-- <router-link
          to="/Home"
          class="tabs-title"
          v-bind:class="{ cur: getCur('Home') }"
        >
          <a-icon type="home" />Home
        </router-link> -->
          <!-- <router-link
            to="/SIKB"
            class="tabs-title"
            v-bind:class="{ cur: getCur('SIKB') }"
          >
            <a-icon type="radar-chart" class="icon" />SIKB
          </router-link> -->
        </div>
        <a-dropdown class="users">
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            <a-avatar size="large" icon="user" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a href="javascript:;" @click="handleLogout"
                ><a-icon type="logout" /> Log out</a
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </header>
      <router-view />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from "vuex"
import Lottie from "@/components/lottie.vue"
import { login, logout, users } from "@/api/modules/login"
import { updateURL } from "@/store/analysis/func"

import "./App.scss"

export default {
  name: "app",
  components: {
    lottie: Lottie,
  },
  computed: {
    ...mapState("analysis", ["spinning"]),
  },
  // ----------------------------后退相关------------------------------------
  // 挂载完成后，判断浏览器是否支持popstate
  mounted() {
    // if (window.history && window.history.pushState) {
    //   history.pushState(null, null, document.URL) //这里有没有都无所谓，最好是有以防万一
    //   window.addEventListener("popstate", this.goBack, false)
    //   // 回退时执行goback方法
    // }
    users()
      .then((res) => {
        this.$store.commit("analysis/setAuthenticated", true)
      })
      .catch((error) => {
        if (error.code === 401) {
          this.$store.commit("analysis/setAuthenticated", false)
          this.$router.push({ name: "Login" })
        }
      })
      .finally(() => {
        this.$store.commit("analysis/setSpinning", false)
      })
  },
  // 页面销毁时，取消监听。否则其他vue路由页面也会被监听
  destroyed() {
    // window.removeEventListener("popstate", this.goBack, false)
  },
  data() {
    return {
      pageIndex: 0,
      mate: this.$route.meta,
      defaultOptions: {
        path:
          "https://labs.nearpod.com/bodymovin/demo/al_boardman/articulation/articulation.json",
      },
    }
  },
  methods: {
    handleLogout() {
      logout().then(() => {
        this.$store.commit("analysis/setAuthenticated", false)
        this.$router.push({ name: "Login" })
      })
    },
    goBack() {
      if (this.pageIndex == 5) {
        this.selLeftSlider(1, "noscroll")
        return false
      } else {
        this.updateURL({ ...this.$route.query, ...this.$route.params })
      }
    },
    //打开选项卡
    selLeftSlider(e, scroll) {
      this.pageIndex = e
      if (e == 2) {
        this.isRouter = false
        this.getOrder()
        this.userExpress()
      } else if (e == 1) {
        if (scroll == "noscroll") {
          this.getCourseList("noscroll")
        } else {
          this.getCourseList()
        }
      } else if (e == 3) {
        this.dataDownload()
      } else if (e == 5) {
        history.pushState(null, null, document.URL)
        //这一步是关键 你不添加一个的话，下次你回退，就算你触发了goback函数，一样回跳回首页。
        //因为history中不存在那个空路径。。
      }
    },
    getHideHeader() {
      return (
        !(this.$route.meta.hide || this.$route.query.hide === "true") &&
        !this.getCur("Login")
      )
    },
    getCur(name) {
      return this.$route.name ? this.$route.name.indexOf(name) !== -1 : ""
    },
    updateURL,
  },
}
</script>
