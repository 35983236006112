import axios from "axios"
import http from "../index"

export const login = (formData) => {
  var bodyFormData = new FormData()
  bodyFormData.append("username", formData.username)
  bodyFormData.append("password", formData.password)
  return axios({
    method: "post",
    url: "/api/v1/login",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export const logout = (token) => {
  return http.request({
    url: "/api/v1/logout",
    method: "post",
    data: token,
  })
}

export const users = (token) => {
  return http.request({
    url: "/api/v1/current",
    method: "get",
  })
}
