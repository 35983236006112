import {
  calculatePercent,
  formatData,
  formatNum,
  getIndex,
  setCorrelationOption,
  setDistributionOption,
  setMatrixData,
  showMessageName,
  splice,
} from "../analysis/func"
import { trainForm, initForm, testForm } from "@/store/analysis/const"
import max from "lodash/max"

let timeout

export const mutations = {
  setAuthenticated(state, data) {
    if (data) {
      localStorage.setItem("isAuthenticated", data)
    } else {
      localStorage.removeItem("isAuthenticated")
    }
    state.isAuthenticated = data
  },
  setRouter(state, data) {
    state.query.tab = data.tab
    state.query.id = data.id
    state.query.did = data.did
    state.query.mid = data.mid
    state.query.modelName = data.modelName
    state.query.vid = data.vid
    state.query.tid = data.tid
  },
  setRoutes(state, routes) {
    state.routes = routes
  },
  setLoading(state, flag) {
    state.loading = flag
  },
  setSpinning(state, flag) {
    if (state.spinning) {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        state.spinning = flag
      }, 1000)
    } else {
      state.spinning = flag
    }
  },
}
