export function updateURL(
  { id, did, tab, mid, modelName, tid, vid, name = "LearningAnalysis" },
  cover = false
) {
  if (!localStorage.getItem("isAuthenticated")) {
    return
  }
  let that = this
  if (that.query && !cover) {
    id = id === undefined ? that.query.id : id
    did = did === undefined ? that.query.did : did
    tab = tab === undefined ? that.query.tab : tab
    mid = mid === undefined ? that.query.mid : mid
    modelName = modelName === undefined ? that.query.modelName : modelName
    tid = tid === undefined ? that.query.tid : tid
    vid = vid === undefined ? that.query.vid : vid
  }
  that.$store.commit("analysis/setRouter", {
    id,
    did,
    tab,
    mid,
    modelName,
    tid,
    vid,
  })
  that.$router.push({
    name: name,
    query: {
      id,
      tab,
      did,
      mid,
      modelName,
      tid,
      vid,
    },
  })
  return {
    id,
    did,
    tab,
    mid,
    modelName,
    tid,
    vid,
  }
}
